import React from 'react';
import {Form, Button, Container} from 'react-bootstrap';
import '../style.css';
import sha256 from 'crypto-js/sha256';

// import background from '../../resources/login-background.png';
import background from '../../resources/sitech-background.jpg';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitLoginForm = props.onSubmitLoginForm || null;
    this.state = {
      values: {
        name: 'no name',
        userName: 'admin',
        password: 'admin',
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  componentDidMount() {
  }

  onChangeName(event) {
    this.setState(state => state.values.name = event.target.value);
  }

  onChangeUserName(event) {
    this.setState(state => state.values.userName = event.target.value);
  }

  onChangePassword(event) {
    this.setState(state => state.values.password = event.target.value);
  }

  handleSubmit(event) {
    // const form = event.currentTarget;
    // console.log(this.values.name + " " + this.values.userName + " " + this.values.password);
    // window.api.send('asynchronous-message', 'dialog', null, null, 'A name was submitted: ' + this.state.values.name);
    // event.preventDefault();
    if (this.onSubmitLoginForm != null) {
      this.onSubmitLoginForm(this.state.values.userName, sha256(this.state.values.password).toString(), this.state.values.name);
    }
    event.preventDefault();
  };

  render() {
    return (
      <div style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: '2em'
      }}>
        <Container className="box">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Tên người dùng</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tên người dùng, VD: Nam"
                value={this.state.values.name}
                onChange={this.onChangeName}
              />
            </Form.Group>

            <Form.Group controlId="formUserName">
              <Form.Label>Tài khoản</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nhập tài khoản"
                value={this.state.values.userName}
                onChange={this.onChangeUserName}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Mật khẩu</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập mật khẩu"
                value={this.state.values.password}
                onChange={this.onChangePassword}
              />
            </Form.Group>

            <Button type="submit" className="submit-button">
              Đăng nhập
            </Button>
          </Form>
        </Container>
      </div>
    )
  }
}

export default LoginPage;